<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card title="Produits Distributeur proche de la peremption">
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Date de début"
                label-for="nom-input"
              >

                <b-form-input
                  id="nom-input"
                  ref="date1"
                  v-model="dateDebut"
                  type="date"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date de fin"
                label-for="nom-input"
              >

                <b-form-input
                  id="nom-input"
                  ref="date2"
                  v-model="dateFin"
                  type="date"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <div style="text-align: center">
                <b-overlay
                  :show="show"
                  rounded="sm"
                  spinner-variant="primary"
                  spinner-small
                  class="d-inline-block"
                >
                  <b-button
                    id="toggle-btn"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.distributeur-modal-prevent-closing
                    variant="outline-primary"
                    class="btn_export"
                    @click="getProduitsPeremption"
                  >
                    <feather-icon icon="SearchIcon"/>
                    Filtrer
                  </b-button>
                </b-overlay>
              </div>

            </b-col>
          </b-row>
          <hr>
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Recherche
                </label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Recherche"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
            <!-- primary -->
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              text="Exporter"
              variant="primary"
              class="ml-2 btn_export"
            >
              <b-dropdown-item @click="exportCSV1">
                CSV
              </b-dropdown-item>
              <b-dropdown-item @click="exportExcel">
                MS-Excel
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <vue-good-table
            id="list"
            :key="componentKey"
            ref="myTable"
            :columns="columns1"
            :rows="rows1"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Status -->
              <span v-if="props.column.field === 'nbr_tournes'">

                {{ props.row.nbr_tournes_ok }} /

              </span>
              <span v-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <!--                  <feather-icon-->
                <!--                    icon="Edit2Icon"-->
                <!--                    class="mr-50"-->
                <!--                  />-->
                <!--                  <span>Edit</span>-->
                <b-button-group>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    :to="{ name: 'commerciaux-details', params: { id: props.row.uuid } }"
                  >
                    Voir
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="handleUpdate(props.row)"
                  >
                    Modifier
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    @click="handleDelete(props.row.uuid)"
                  >
                    Supprimer
                  </b-button>
                </b-button-group>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Affichage de 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> sur {{ props.total }} élements </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="Visites par commercial et jour">
          <b-row>
            <b-col
              v-if="commerciaux.length"
              md="4"
            >
              <b-form-group
                label="Commerciaux"
                label-for="commerciaux"
              >
                <v-select
                  v-model="commercial"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="full_name"
                  :options="commerciaux"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Date de début"
                label-for="nom-input"
              >

                <b-form-input
                  id="nom-input"
                  ref="date1"
                  v-model="dateDebutVisite"
                  type="date"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Date de fin"
                label-for="nom-input"
              >

                <b-form-input
                  id="nom-input"
                  ref="date2"
                  v-model="dateFinVisite"
                  type="date"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <div style="text-align: center">
                <b-overlay
                  :show="show1"
                  rounded="sm"
                  spinner-variant="primary"
                  spinner-small
                  class="d-inline-block"
                >
                  <b-button
                    id="toggle-btn"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.distributeur-modal-prevent-closing
                    variant="outline-primary"
                    class="btn_export"
                    @click="getVisitesCommercialJour"
                  >
                    <feather-icon icon="SearchIcon"/>
                    Filtrer
                  </b-button>
                </b-overlay>
              </div>

            </b-col>
          </b-row>
          <hr>
          <hr>
          <VisitesCommercialJourChart
            :key="componentVCJCKey"
            :data="visitesCommercialJourChartData"
          />

        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BCard, BCol, BDropdown, BDropdownItem, BFormGroup, BFormInput, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { API_URL } from '@/helpers/global-scops'
import VisitesCommercialJourChart from '@/views/m-components/dataAnalyseCharts/VisitesCommercialJourChart.vue'
import vSelect from 'vue-select'
import VueGoodTable from 'vue-good-table/src/components/Table.vue'
import store from '@/store'
import { generateConcatenatedWord } from '@/helpers/methodes'
import * as XLSX from 'xlsx'

export default {
  name: 'MainVue',
  components: {
    BOverlay,
    BPagination,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    VueGoodTable,
    vSelect,
    VisitesCommercialJourChart,
    BFormInput,
    BButton,
    BFormGroup,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      show1: false,
      pageLength: 10,
      searchTerm: '',
      commerciaux: [],
      commercial: null,
      visitesCommercialJourChartData: [],
      componentKey: 0,
      componentVCJCKey: 0,
      dateDebut: '',
      dateFin: '',
      dateDebutVisite: '',
      dateFinVisite: '',
      chartDataStackedColumnChart: [],
      series: [
        {
          valueField: 'afridia_availability_rate',
          seriesName: 'Jus Afridia'
        },
        {
          valueField: 'concurrent_availability_rate',
          seriesName: 'Jus Concurrent'
        },
        {
          valueField: 'actions_done_rate',
          seriesName: 'Actions Done'
        },
      ],
      rows1: [],
      columns1: [
        {
          label: 'Product',
          field: 'produit.produit',
          filterable: true,
        },
        {
          label: 'Date de peremption',
          field: 'date_peremption',
          filterable: true,
        },
        {
          label: 'Quantité',
          field: 'quantite',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Jours restants',
          field: 'jours_restants',
          filterable: true,
        },
        {
          label: 'Distributeur',
          field: 'distributeur',
          filterable: true,
        },
        {
          label: 'Commercial',
          field: 'commercial',
          filterable: true,
        },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted() {
    this.dateDebut = this.getFirstDayOfCurrentMonth()
    this.dateFin = this.getCurrentDate()
    this.dateDebutVisite = this.getBeforeYesterday()
    this.dateFinVisite = this.getCurrentDate()
    this.getVisitesCommercialJour()
    this.getCommercials()
    this.getProduitsPeremption()
  },
  methods: {
    exportExcel() {
      const header = this.columns1.map(column => column.label)

      const data = [header].concat(this.rows1.map(row => this.columns1.map(column => {
        if (column.field.includes('.')) {
          const fields = column.field.split('.')
          return row[fields[0]] ? row[fields[0]][fields[1]] : ''
        }
        return row[column.field]
      })))

      // Créer un workbook avec une feuille
      const ws = XLSX.utils.aoa_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Export')

      // Générer le fichier et le télécharger
      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      })
      const blob = new Blob([wbout], { type: 'application/octet-stream' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${generateConcatenatedWord('Produits_Distributeur_proche_peremption')}.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    exportCSV1() {
      console.log(this.rows1)
      const header = this.columns1.map(column => column.label)
      const data = this.rows1.map(row => this.columns1.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(','))
        .join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${generateConcatenatedWord('Produits_Distributeur_proche_peremption')}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    getProduitsPeremption() {
      this.show = true
      this.$http.get(`${API_URL}produits-peremption/?date_debut=${this.dateDebut}&date_fin=${this.dateFin}`)
        .then(response => {
          this.show = false
          this.rows1 = response.data
          console.log(this.rows1)
        })
        .catch(error => {

          console.log(error)
        })
    },
    getFirstDayOfCurrentMonth() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (1 + date.getMonth()).toString()
        .padStart(2, '0')
      return `${year}-${month}-01`
    },
    getCurrentDate() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (1 + date.getMonth()).toString()
        .padStart(2, '0')
      const day = date.getDate()
        .toString()
        .padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    getVisitesCommercialJour() {
      this.show1 = true
      let uuid = ''
      if (this.commercial) {
        uuid = `uuid=${this.commercial.uuid}`
      }
      this.$http.get(`${API_URL}visites-commercial-jour/?date_debut=${this.dateDebutVisite}&date_fin=${this.dateFinVisite}&${uuid}`)
        .then(response => {
          this.show1 = false
          this.visitesCommercialJourChartData = response.data
          this.componentVCJCKey += 1
          console.log(this.visitesCommercialJourChartData)
        })
        .catch(error => {
          this.show1 = false
          console.log(error)
        })
    },
    getBeforeYesterday() {
      const date = new Date()
      date.setDate(date.getDate() - 2)
      const year = date.getFullYear()
      const month = (1 + date.getMonth()).toString()
        .padStart(2, '0')
      const day = date.getDate()
        .toString()
        .padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    getCommercials() {
      this.$http.get(`${API_URL}commercials/`)
        .then(response => {
          this.commerciaux = response.data
          console.log(this.commerciaux)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>

</style>
